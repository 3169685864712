import { getProp } from '@/api/parsing'
import { useAuthentication, Note } from '@alexandrainst/plana-react-api'

export const useNoteDetails = (note?: Note) => {
  const {
    state: { externalSystemId: me },
  } = useAuthentication()

  const seenByMe =
    me !== '' && (getProp(note, 'seen_by') ?? '').split(',').includes(me)
  const author = getProp(note, 'editor') ?? note?.data.editor ?? ''
  const isMine: boolean =
    me !== undefined && author !== undefined && author === me
  return { seenByMe, isMine, author }
}
