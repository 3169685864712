import { useResource } from '@/api'
import { useAuthentication } from '@alexandrainst/plana-react-api'

export type UserState = {
  userId: string
  employeeId: string
  displayName: string
}

export const useCurrentUser = (): UserState => {
  const {
    state: { authenticated, externalSystemId, userid },
  } = useAuthentication()
  const { data: employee } = useResource('Employee', externalSystemId)
  if (authenticated && employee) {
    return {
      userId: userid,
      employeeId: employee?.data.id,
      displayName: employee?.data.fullname,
    } as const
  }
  return { userId: '', employeeId: '', displayName: '' } as const
}
