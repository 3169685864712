import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStyles } from './ServiceVisitEditor.style'
import { PropertySchemas } from '@/api/parsing'
import { parsedProp } from '@/api/parsing/properties'
import { VisitState } from '@/common-util/types'
import { ActualTimeEditor } from './ActualTimeEditor'
import { useAppSelector } from '@/app'
import { selectors as coreSelectors } from '@/features/core'
import { useServiceVisitEditor } from './ServiceVisitEditorContext'
import { useState } from 'react'
import { useCancellationsModel } from '@/features/cancellations'

type ServiceVisitEditorControlsProps = {
  onClose?: () => void
}
export const ServiceVisitEditorControls = ({
  onClose,
}: ServiceVisitEditorControlsProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false)

  const { useActualTime: actualTimeFeatureActivated } = useAppSelector(
    coreSelectors.selectFlags
  )

  const {
    serviceVisitData: { visit, cancellation },
    setState: setVisitState,
    mutationStatus,
  } = useServiceVisitEditor()

  const visitState = visit
    ? parsedProp(
        visit?.data.properties,
        PropertySchemas.serviceVisit.visitState
      )
    : 'Unknown'

  const { cancellations, isLoading: isLoadingCancellations } =
    useCancellationsModel()

  const cancelVisit = (cancellationId: string) => {
    setState('Cancelled', cancellationId)
    setCancellationDialogOpen(false)
  }

  const handleToggleChange = (_: unknown, newState: VisitState) => {
    if (newState === 'Cancelled') {
      setCancellationDialogOpen(true)
      return
    }

    if (newState !== null) {
      setState(newState)
    }
  }

  const convertToViewState = (state: VisitState): VisitState => {
    switch (state) {
      case 'Cancelled':
      case 'Rejected':
      case 'Inactive':
        return 'Cancelled'
      case 'Unknown':
        return 'Accepted'
      default:
        return state
    }
  }

  const toggleControl = {
    value: convertToViewState(visitState ?? 'Accepted'),
    onChange: handleToggleChange,
    exclusive: true,
    disabled:
      mutationStatus !== 'success' &&
      mutationStatus !== 'idle' &&
      visit === undefined,
  }

  const setState = (state: VisitState, cancellationId?: string) => {
    if (visit !== undefined) {
      setVisitState(state, cancellationId)
    }
  }

  return (
    <>
      <Stack spacing={1} className={classes.controlsContainer}>
        <ToggleButtonGroup size="small" {...toggleControl}>
          <ToggleButton value="Cancelled" color="primary">
            {t('common.state.cancelled')}
          </ToggleButton>
          <ToggleButton value="Accepted" color="primary">
            {t('common.state.accepted')}
          </ToggleButton>
          <ToggleButton value="Completed" color="primary">
            {t('common.state.completed')}
          </ToggleButton>
        </ToggleButtonGroup>
        {visitState === 'Cancelled' && cancellation !== undefined && (
          <Typography variant="caption">{cancellation?.name}</Typography>
        )}
        <Box className={classes.buttonContainer}>
          {actualTimeFeatureActivated && visit !== undefined && (
            <ActualTimeEditor />
          )}
          <Button onClick={onClose}>{t('common.close')}</Button>
        </Box>
      </Stack>
      <Dialog open={cancellationDialogOpen}>
        <DialogTitle>{t('cancellations.choose')}</DialogTitle>
        <DialogContent>
          {isLoadingCancellations ? (
            <CircularProgress />
          ) : (
            <Stack direction="column" spacing={0.5}>
              {cancellations?.map((c, i) => (
                <Button
                  variant="outlined"
                  key={i}
                  onClick={() => cancelVisit(c.id)}
                >
                  {c.name}
                </Button>
              ))}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancellationDialogOpen(false)}>
            {t('cancellations.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
