import { useServerLog, useUpdateResource } from '@/api'
import { PropertySchemas, parsedProp } from '@/api/parsing'
import { deleteProp, setParsedProp } from '@/api/parsing/properties'
import { useCurrentUser } from '@/features/core'
import { ServiceVisit } from '@alexandrainst/plana-react-api'
import dayjs from 'dayjs'
import { produce } from 'immer'
import { useCallback, useMemo, useState } from 'react'

type ActualTime = {
  begin: dayjs.Dayjs | null
  end: dayjs.Dayjs | null
}

export const useActualTime = (visit: ServiceVisit | undefined) => {
  const { info } = useServerLog()
  const { employeeId } = useCurrentUser()
  const { mutate, status: mutationStatus } = useUpdateResource('ServiceVisit')
  const [actualTime, setActualTime] = useState<ActualTime>({
    begin: null,
    end: null,
  })

  const visitState = visit
    ? parsedProp(
        visit?.data.properties,
        PropertySchemas.serviceVisit.visitState
      )
    : 'Unknown'

  const setActualWhen = ({ begin, end }: ActualTime) => {
    if (visit !== undefined) {
      setActualTime({ begin, end })
      const b =
        begin ??
        dayjs(visit.data.date, 'YYYY-MM-DD').add(visit.data.from, 'minute')
      const e = end ?? b.add(visit.data.duration, 'minute')
      const newPropValue =
        begin === null && end === null
          ? null
          : `${Math.round(Math.abs(dayjs.duration(dayjs(visit.data.date, 'YYYY-MM-DD').diff(b)).asMinutes()))}:${Math.round(
              Math.max(0, dayjs.duration(e.diff(b)).asMinutes())
            )}`

      const setCompleted = begin !== null && end !== null
      const withActualWhen = produce(
        newPropValue === null
          ? deleteProp(visit, PropertySchemas.serviceVisit.actualWhen)
          : setParsedProp(
              visit,
              PropertySchemas.serviceVisit.actualWhen,
              newPropValue
            ),
        draft => {
          draft.data.origin = 'Calendar'
          if (employeeId && employeeId !== '') {
            draft.data.editor = employeeId
          }
        }
      )
      if (setCompleted) {
        mutate(
          setParsedProp(
            withActualWhen,
            PropertySchemas.serviceVisit.visitState,
            'Completed'
          )
        )
      } else {
        mutate(withActualWhen)
      }
      info(`Setting actualWhen of visit ${visit.data.id} to ${newPropValue}`)
    }
  }

  const actualWhenProperty = parsedProp(
    visit,
    PropertySchemas.serviceVisit.actualWhen
  )

  const propertyToBeginEnd = useCallback(
    (prop: string) => {
      const from = Number(prop?.split(':')[0])
      const duration = Number(prop?.split(':')[1])
      if (from !== undefined && duration !== undefined) {
        const begin = dayjs(visit?.data.date, 'YYYY-MM-DD').add(from, 'minute')
        const end =
          visitState === 'Completed' ? begin.add(duration, 'minute') : null
        return { begin, end }
      }
      return actualTime
    },
    [actualTime, visit?.data.date, visitState]
  )

  const resultingActualTime = useMemo(() => {
    if (actualWhenProperty !== undefined) {
      return propertyToBeginEnd(actualWhenProperty)
    } else if (visitState === 'Completed' && visit !== undefined) {
      const begin = dayjs(visit.data.date, 'YYYY-MM-DD').add(
        visit.data.from,
        'minute'
      )
      const end = begin.add(visit.data.duration, 'minute')
      return { begin, end }
    }
    return actualTime
  }, [actualWhenProperty, visitState, visit, actualTime, propertyToBeginEnd])

  const actualTimeModified = useMemo(() => {
    if (actualWhenProperty !== undefined) {
      return true
    }
    if (actualTime.begin !== null || actualTime.end !== null) {
      if (visit !== undefined) {
        const begin = dayjs(visit?.data.date, 'YYYY-MM-DD').add(
          visit.data.from,
          'minute'
        )
        const end = begin.add(visit.data.duration, 'minute')
        if (begin === actualTime.begin && end === actualTime.end) {
          return false
        }
      }
      return true
    }
    return false
  }, [visit, actualWhenProperty, actualTime])

  const handleComplete = () => {
    if (actualWhenProperty !== undefined) {
      const prop = propertyToBeginEnd(actualWhenProperty)
      setActualWhen({ begin: prop?.begin, end: dayjs() })
      return true
    }
    if (actualTime.begin !== null && actualTime.end === null) {
      setActualWhen({ begin: actualTime.begin, end: dayjs() })
      return true
    }
    return false
  }

  const buttonsDisabled =
    mutationStatus !== 'success' &&
    mutationStatus !== 'idle' &&
    visit === undefined

  const isAfterMidnight =
    resultingActualTime.begin?.isAfter(
      dayjs(visit?.data.date, 'YYYY-MM-DD').add(1, 'day')
    ) ?? false

  const toggleAfterMidnight = () => {
    if (resultingActualTime.begin !== null) {
      if (isAfterMidnight) {
        setActualWhen({
          begin: resultingActualTime.begin.subtract(1, 'day'),
          end: resultingActualTime.end?.subtract(1, 'day') ?? null,
        })
      } else {
        setActualWhen({
          begin: resultingActualTime.begin.add(1, 'day'),
          end: resultingActualTime.end?.add(1, 'day') ?? null,
        })
      }
    }
  }

  return {
    actualTime,
    actualTimeModified,
    resultingActualTime,
    actualWhenProperty,
    setActualWhen,
    handleComplete,
    buttonsDisabled,
    isAfterMidnight,
    toggleAfterMidnight,
  }
}
