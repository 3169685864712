import { useResourcesBySource, useUpdateResource } from '@/api'
import dayjs from 'dayjs'
import {
  Note as NoteType,
  useAuthentication,
} from '@alexandrainst/plana-react-api'
import { v4 as uuidv4 } from 'uuid'
import { dateFormat, dateTimeFormat } from '@/common-util/dates'
import { setProp } from '@/api/parsing'
import { NotesView } from './NotesView'
import { useServerLog } from '@/api/hooks'

interface CustomerNotesProps {
  id: string
  from?: string
  to?: string
  raw?: boolean
}
export const CustomerNotes = ({
  id,
  from,
  to,
  raw = false,
}: CustomerNotesProps) => {
  const { info } = useServerLog()
  const { data: notes, isLoading } = useResourcesBySource(
    'Note',
    'Customer',
    id,
    from ?? dayjs().format(dateFormat),
    to ?? '-'
  )
  const {
    state: { externalSystemId: me },
  } = useAuthentication()
  const { mutate: updateNote } = useUpdateResource('Note')
  const handleNewNote = (message: string) => {
    const note: NoteType = {
      kind: 'dispatch#note',
      data: {
        id: uuidv4(),
        resourceId: id,
        date: from ?? dayjs().format(dateFormat),
        expire: dayjs().add(28, 'days').format(dateTimeFormat),
        note: message,
        created: dayjs().format(dateTimeFormat),
        editor: me,
        properties: JSON.stringify(setProp({}, 'seen_by', me)),
        state: 'Created',
        timestamp: dayjs().format(dateTimeFormat),
        timestamp_m: dayjs().valueOf(),
        version: 0,
      },
    }
    info(`Adding note to visit ${id}`)
    updateNote(note)
  }
  if (notes) {
    return (
      <NotesView notes={notes ?? []} raw={raw} onCreateNote={handleNewNote} />
    )
  }
  if (isLoading) {
    return <>Loading...</>
  }
  return <>Error!</>
}
