import { Button, Container, Stack, TextField } from '@mui/material'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import { Note as NoteType } from '@alexandrainst/plana-react-api'
import { Note } from './Note'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  notes: NoteType[]
  onCreateNote?: (message: string) => void
  raw?: boolean
}
export const NotesView = ({ notes, onCreateNote, raw = false }: Props) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }
  const createMessage = () => {
    onCreateNote?.(message)
    setMessage('')
  }
  return (
    <Container>
      <Stack spacing={2} pb={1}>
        {[...notes]
          .sort((a, b) => b.data.timestamp_m - a.data.timestamp_m)
          .map(note => (
            <Note key={note.data.id} id={note.data.id} raw={raw} />
          ))}
        <TextField
          id="message-entry"
          label={t('notes.Message')}
          multiline
          maxRows={4}
          fullWidth
          value={message}
          onChange={handleChange}
        />
        <Button
          disabled={message === ''}
          variant="contained"
          endIcon={<MailOutlineOutlinedIcon />}
          onClick={createMessage}
        >
          {t('notes.createMessage')}
        </Button>
      </Stack>
    </Container>
  )
}
