import { useResourcesBySource } from '@/api'
import {
  CalendarColumns,
  RouteHeaderContainer,
} from '@/features/calendar/components'
import { LinearProgress, Paper } from '@mui/material'
import { useStyles } from './EmployeeCalendar.style'
import { useAutoscroll } from '@/features/calendar/util'
import { useAppSelector } from '@/app'
import { selectors as coreSelectors } from '@/features/core'
import { ServiceVisitList } from '../calendar/components/ServiceVisitList'
import { calendarModelMapper } from '@/features/calendar/domain'
import { CALENDAR_LAST_HOUR } from '@/app/constants'
import { removeChildrenFilter } from '../calendar/domain/serviceVisitModel'

interface Props {
  id: string
  date: string
  scale?: number
  gutterInterval?: number
  legacyGrouping?: boolean
}
export const EmployeeCalendar = ({
  id,
  date,
  scale = 1,
  gutterInterval = 30,
  legacyGrouping = false,
}: Props) => {
  const { classes } = useStyles()
  const { hideInactiveVisits } = useAppSelector(coreSelectors.selectFlags)
  const { isLoading, data: visits } = useResourcesBySource(
    'ServiceVisit',
    'Employee',
    id,
    date,
    date,
    {
      select: data =>
        data
          .filter(it => it.data.state !== 'Deleted')
          .filter(it =>
            hideInactiveVisits
              ? !calendarModelMapper.mapServiceVisit(it).isInactive()
              : true
          )
          .filter(removeChildrenFilter),
    }
  )

  const items = (visits ?? []).map(it => ({
    position: it.data.from,
    size: it.data.duration,
    item: it,
  }))
  const { data: dayRouteHeaders } = useResourcesBySource(
    'DayRouteHeader',
    'Employee',
    id,
    date,
    date
  )

  const { ref: scrollContainerRef } = useAutoscroll(date, items, scale)
  return (
    <>
      <LinearProgress
        variant={isLoading ? 'indeterminate' : 'determinate'}
        value={100}
      />
      <Paper elevation={3} className={classes.container}>
        <div ref={scrollContainerRef} className={classes.routeContainer}>
          <RouteHeaderContainer
            routes={
              dayRouteHeaders?.map(d => ({
                id: d.data.route,
                date: d.data.date ?? '',
              })) ?? []
            }
          />
          <CalendarColumns
            scale={scale}
            totalMinutes={CALENDAR_LAST_HOUR * 60}
            legacyGrouping={legacyGrouping}
            gutterInterval={gutterInterval}
          >
            <ServiceVisitList
              visits={visits ?? []}
              scale={scale}
              legacyLayout={legacyGrouping}
            />
          </CalendarColumns>
        </div>
      </Paper>
    </>
  )
}
