import IosShareIcon from '@mui/icons-material/IosShare'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen'
import {
  Avatar,
  Box,
  Card,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  Typography,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { LanguageSwitcher } from '@/features/i18n'
import { useStyles } from './InstallationGuide.style'
import { blue } from '@mui/material/colors'
import { VersionInformation } from '@/features/app-information/VersionInformation'
import { Copyright } from '@/features/app-information/Copyright'
import { FC } from 'react'
import { PlatformType } from '@/features/app-information/types'
import { assertUnreachable } from '@alexandrainst/assert-unreachable'

type Props = {
  platform: PlatformType
}

export const InstallationGuide: FC<Props> = ({ platform }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('', { keyPrefix: 'installationGuide' })

  let platformGuide = <></>

  switch (platform) {
    case 'ios':
      platformGuide = (
        <>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[300] }}>1</Avatar>
            </ListItemAvatar>
            <Trans
              i18nKey="step1"
              t={t}
              components={{
                typo: <Typography variant="body1" />,
                ico: <IosShareIcon sx={{ paddingLeft: '0.5ch' }} />,
              }}
            >
              <Typography variant="body1">Tap</Typography>
              <IosShareIcon />
              <Typography variant="body1">, then</Typography>
            </Trans>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[300] }}>2</Avatar>
            </ListItemAvatar>
            <Trans
              t={t}
              i18nKey="step2"
              components={{
                typo: <Typography variant="body1" />,
                ico: <AddBoxOutlinedIcon sx={{ paddingLeft: '1ch' }} />,
              }}
            >
              <Typography variant="body1">Add to Home Screen</Typography>
              <AddBoxOutlinedIcon />
            </Trans>
          </ListItem>
        </>
      )
      break
    case 'android':
      platformGuide = (
        <ListItem>
          <Trans
            t={t}
            i18nKey="android"
            components={{
              typo: <Typography variant="body1" />,
              ico: <AddToHomeScreenIcon sx={{ paddingLeft: '0.5ch' }} />,
            }}
          >
            <Typography variant="body1">Add to Home Screen</Typography>
            <AddToHomeScreenIcon />
          </Trans>
        </ListItem>
      )
      break
    case 'unknown':
      platformGuide = (
        <ListItem>
          <Trans
            t={t}
            i18nKey="unknownPlatform"
            components={{
              typo: <Typography variant="body1" />,
            }}
          >
            <Typography variant="body1">
              Look for a menu item mentioning something similar to &quot;Install
              app&quot;
            </Typography>
          </Trans>
        </ListItem>
      )
      break
    default:
      assertUnreachable(platform)
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img src="plana.svg" width="20%" className={classes.logo} />
            <Typography variant="h6">{t('title')}</Typography>
          </Box>
          <Card
            elevation={8}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              <ListItem>
                <Typography variant="body1">
                  <Trans i18nKey="installToHomeScreen" t={t}>
                    Install <strong>PlanA</strong> as an application to the home
                    screen.
                  </Trans>
                </Typography>
              </ListItem>
              {platformGuide}
            </List>
          </Card>
          <LanguageSwitcher />
        </Stack>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <VersionInformation />
      </Box>
    </Container>
  )
}
