import { dateTimeFormat } from '@/common-util/dates'
import { PropertySchemas } from '@/api/parsing'
import { parsedProp } from '@/api/parsing/properties'
import { CustomerNotes } from '@/features/note'
import { useServiceVisitEditor } from '@/incubator/ServiceVisitEditor/ServiceVisitEditorContext'
import { GeoLocation } from '@alexandrainst/plana-react-api'
import { ExpandLess, ExpandMore, KeyOutlined } from '@mui/icons-material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import MapIcon from '@mui/icons-material/Map'
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined'
import PeopleIcon from '@mui/icons-material/People'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './ServiceVisitEditorContent.style'

const getDisplayAddress = (gl: GeoLocation) => {
  const writtenAddress = parsedProp(
    gl,
    PropertySchemas.geoLocation.writtenAddress
  )
  if (writtenAddress !== undefined) {
    return writtenAddress
  }
  return gl.data.address
}

export const ServiceVisitEditorContent = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const {
    serviceVisitData: {
      customer,
      customerDescription,
      description,
      keyInformation,
      notes,
      phone,
      relatives,
      relativesPhoneNumbers,
      serviceAgreement,
      visit,
      showDebug,
    },
  } = useServiceVisitEditor()

  const adhocDesc = parsedProp(visit, PropertySchemas.serviceVisit.adhocDesc)

  const debug = useMemo(() => {
    if (showDebug) {
      return (
        <>
          <pre>{JSON.stringify(visit, undefined, 2)}</pre>
          <pre>{JSON.stringify(serviceAgreement, undefined, 2)}</pre>
          <pre>{JSON.stringify(customer, undefined, 2)}</pre>
          <pre>{JSON.stringify(notes, undefined, 2)}</pre>
        </>
      )
    }
    return null
  }, [showDebug, visit, serviceAgreement, customer, notes])

  const [openNotes, setOpenNotes] = useState(false)
  const [openRelativesTelephoneNumbers, setOpenRelativesTelephoneNumbers] =
    useState(false)

  const handleRelativesExpand = () => {
    setOpenRelativesTelephoneNumbers(!openRelativesTelephoneNumbers)
  }

  const handleNotesExpand = () => {
    setOpenNotes(!openNotes)
  }
  const loading = visit === undefined
  if (loading) {
    return (
      <Box>
        <Stack spacing={1}>
          <Typography variant="subtitle1" gutterBottom>
            <Skeleton height="5em" />
          </Typography>
          <Divider />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
        </Stack>
      </Box>
    )
  }

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Divider />
      {adhocDesc !== undefined && (
        <Box pt={1}>
          <Typography variant="caption" fontWeight={600}>
            {t('serviceVisitRenderer.adhocDesc')}
          </Typography>
          <Typography variant="body1">{adhocDesc}</Typography>
        </Box>
      )}
      <List className={classes.list}>
        {customer?.data.geoLocations.data.geolocations
          .filter(it => it.data.address)
          .map((gl, idx) => (
            <Box key={idx}>
              {idx > 0 && <Divider variant="inset" component="li" />}
              <ListItem key={idx}>
                <ListItemButton
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    gl.data.address ?? ''
                  )}`}
                >
                  <ListItemIcon>
                    <MapIcon />
                  </ListItemIcon>
                  <ListItemText primary={getDisplayAddress(gl)} />
                </ListItemButton>
              </ListItem>
            </Box>
          ))}

        <Divider component="li" />
        <ListItem>
          <ListItemButton onClick={handleNotesExpand}>
            <ListItemIcon>
              <EmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              {t('planning.notes', {
                count:
                  notes?.filter(note =>
                    dayjs().isBefore(dayjs(note.data.expire, dateTimeFormat))
                  ).length ?? 0,
              })}
            </ListItemText>
            {notes && (openNotes ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        <Collapse
          className={classes.collapse}
          in={openNotes}
          timeout="auto"
          unmountOnExit
        >
          <CustomerNotes id={customer?.data.id ?? ''} />
        </Collapse>

        {phone && (
          <>
            <Divider component="li" />
            <ListItem>
              <ListItemButton
                component="a"
                target="_blank"
                rel="noreferrer"
                href={`tel:${phone}`}
              >
                <ListItemIcon>
                  <PhoneIphoneOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={phone} />
              </ListItemButton>
            </ListItem>
          </>
        )}

        {customerDescription && (
          <>
            <Divider component="li" />
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <NoteOutlinedIcon />
                </ListItemIcon>
                <ListItemText>{customerDescription}</ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        )}

        {keyInformation && (
          <>
            <Divider component="li" />
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <KeyOutlined />
                </ListItemIcon>
                <ListItemText>{keyInformation}</ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        )}

        {relatives && (
          <>
            <Divider component="li" />
            <ListItem>
              <ListItemButton onClick={handleRelativesExpand}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={relatives} />
                {relativesPhoneNumbers.length > 0 &&
                  (openRelativesTelephoneNumbers ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  ))}
              </ListItemButton>
            </ListItem>
            {relativesPhoneNumbers && (
              <Collapse
                in={openRelativesTelephoneNumbers}
                timeout="auto"
                unmountOnExit
                className={classes.collapse}
              >
                <List component="div" disablePadding>
                  {relativesPhoneNumbers.map((tel, idx) => (
                    <ListItem key={idx}>
                      <ListItemButton
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        href={`tel:${tel}`}
                      >
                        <ListItemIcon>
                          <PhoneIphoneOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={tel} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </>
        )}

        {debug}
      </List>
    </Box>
  )
}
