import { dateTimeFormat } from '@/common-util/dates'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface NoteRendererProps {
  created: string
  expires: string
  author: string
  message: string
  isRead?: boolean
  isAuthor?: boolean
  onMarkAsRead?: () => void
}

export const NoteRenderer = ({
  created,
  expires,
  author,
  message,
  isRead = false,
  isAuthor = false,
  onMarkAsRead,
}: NoteRendererProps) => {
  const { t } = useTranslation()
  const daysToExpiration = Math.round(
    dayjs(expires).diff(dayjs(), 'days', true)
  )
  const daysSinceCreation = Math.round(
    dayjs(created, dateTimeFormat).diff(dayjs(), 'days', true)
  )
  return (
    <Card elevation={4}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <b>
            {author}
            {isAuthor && ` (${t('common.me')})`}
          </b>
          {` - ${t('notes.createdDaysAgo', { val: daysSinceCreation })}`}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {t('notes.expiresInDays', { val: daysToExpiration })}
        </Typography>
      </CardContent>
      <CardActions>
        {!isRead && (
          <Button size="small" color="primary" onClick={onMarkAsRead}>
            {t('notes.markAsRead')}
          </Button>
        )}
      </CardActions>
    </Card>
  )
}
