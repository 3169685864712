import { useInstallation } from '@/features/app-information'
import {
  TimePickerProps,
  MobileTimePickerProps,
  MobileTimePicker,
  DesktopTimePicker,
} from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useStyles } from './PlatformSpecificTimePicker.styles'

export const PlatformSpecificTimePicker = (
  props: TimePickerProps<dayjs.Dayjs> & MobileTimePickerProps<dayjs.Dayjs>
) => {
  const { classes } = useStyles()
  const { platform } = useInstallation()
  if (platform === 'ios' || platform === 'android') {
    return <MobileTimePicker {...props} className={classes.datePicker} />
  }
  return <DesktopTimePicker {...props} />
}
