import { useResource, useUpdateResource } from '@/api'
import { useServerLog } from '@/api/hooks'
import { getProp, setProp } from '@/api/parsing'
import {
  Note as NoteType,
  useAuthentication,
} from '@alexandrainst/plana-react-api'
import { useNoteDetails } from './hooks'
import { NoteRenderer } from './NoteRenderer'

interface Props {
  id: string
  raw?: boolean
}
export const Note = ({ id, raw = false }: Props) => {
  const { info } = useServerLog()
  const {
    state: { externalSystemId: me },
  } = useAuthentication()
  const { data: note, isError } = useResource('Note', id)
  const { mutate: mutateNote } = useUpdateResource('Note')
  const { seenByMe, isMine, author } = useNoteDetails(note)
  const { data: employee } = useResource('Employee', author)

  const markAsRead = () => {
    if (note && me && !seenByMe) {
      const newNote = JSON.parse(JSON.stringify(note)) as NoteType
      const seenBy = (getProp(note, 'seen_by') ?? '').split(',')
      seenBy.push(me)
      newNote.data.properties = setProp(
        note.data.properties,
        'seen_by',
        seenBy.join(',')
      ) as string
      info(`Mark note as seen ${note.data.id}`)
      mutateNote(newNote)
    }
  }

  if (raw) {
    return <pre>{JSON.stringify(note, undefined, 2)}</pre>
  }

  if (note) {
    return (
      <NoteRenderer
        created={note.data.created}
        expires={note.data.expire}
        author={`${employee?.data.fullname ?? author}`}
        isAuthor={isMine}
        message={note?.data.note ?? ''}
        isRead={seenByMe}
        onMarkAsRead={markAsRead}
      />
    )
  }
  if (isError) {
    return <>Error!</>
  }
  return <>Loading...</>
}
