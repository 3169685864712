import { ServiceVisitTime } from '@/features/calendar/components'
import { useServiceVisitEditor } from '@/incubator/ServiceVisitEditor/ServiceVisitEditorContext'
import { Box, Typography, Skeleton } from '@mui/material'

export const ServiceVisitEditorTitle = () => {
  const {
    serviceVisitData: { title, visit },
  } = useServiceVisitEditor()
  const loading = visit === undefined
  return (
    <Box>
      <Typography variant="subtitle1">
        {loading ? (
          <Skeleton width="10em" />
        ) : (
          <ServiceVisitTime
            date={visit.data.date ?? ''}
            from={visit.data.from}
            duration={visit.data.duration}
            large
          />
        )}
      </Typography>
      <Typography variant="h5">
        {loading ? <Skeleton width="10em" /> : title}
      </Typography>
    </Box>
  )
}
