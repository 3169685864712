import { PlatformSpecificTimePicker } from './PlatformSpecificTimePicker'
import { Button, IconButton, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useServiceVisitEditor } from './ServiceVisitEditorContext'
import AddCircle from '@mui/icons-material/AddCircle'
import { useStyles } from './ActualTimeEditor.style'

export const ActualTimeEditor = () => {
  const { classes } = useStyles()
  const { t } = useTranslation('', { keyPrefix: 'serviceVisitEditor' })
  const {
    actualTimeData: {
      resultingActualTime: { begin, end },
      setActualWhen: onSetActualWhen,
      actualTimeModified: modified,
      buttonsDisabled,
      isAfterMidnight,
      toggleAfterMidnight,
    },
  } = useServiceVisitEditor()

  const onSetActualBegin = (e: dayjs.Dayjs) => {
    onSetActualWhen({ begin: e, end })
  }

  const onSetActualEnd = (e: dayjs.Dayjs) => {
    onSetActualWhen({ begin, end: e })
  }
  const reset = () => {
    onSetActualWhen({ begin: null, end: null })
  }

  return (
    <Stack spacing={1} direction="row">
      {begin === null ? (
        <Button
          disabled={buttonsDisabled}
          onClick={() => onSetActualBegin(dayjs())}
        >
          {t('start')}
        </Button>
      ) : (
        <>
          <IconButton
            disableRipple={true}
            aria-label={t('afterMidnight')}
            size="small"
            className={classes.midnightButton}
            onClick={toggleAfterMidnight}
          >
            <AddCircle
              fontSize="inherit"
              color={isAfterMidnight ? 'primary' : 'disabled'}
            />
          </IconButton>
          <PlatformSpecificTimePicker
            slotProps={{ textField: { size: 'small' } }}
            label={t('actualFrom')}
            ampm={false}
            value={begin}
            onAccept={e => {
              if (e) onSetActualBegin(e)
            }}
          />
        </>
      )}
      {begin !== null && end !== null && (
        <PlatformSpecificTimePicker
          slotProps={{ textField: { size: 'small' } }}
          label={t('actualTo')}
          ampm={false}
          value={end}
          onAccept={e => {
            if (e) onSetActualEnd(e)
          }}
        />
      )}
      {modified && (
        <Button disabled={buttonsDisabled} onClick={reset}>
          {t('reset')}
        </Button>
      )}
    </Stack>
  )
}
