import { useAppDispatch, useAppSelector } from '@/app'
import { Grid, Box } from '@mui/material'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { useEffect } from 'react'
import { DateController } from '@/features/calendar/controllers'
import { EmployeeCalendar } from './EmployeeCalendar'
import { actions, selectors } from './personalPlanSlice'
import {
  GlobalLoadingIndicator,
  selectors as coreSelectors,
} from '@/features/core'
import { ServiceVisitEditorDialog } from '@/incubator/ServiceVisitEditor/ServiceVisitEditor'
import { ZoomController } from '../calendar/controllers/ZoomController'
import { CalendarProvider } from '@/features/calendar'

export const PersonalPlanMainView = () => {
  const {
    calendar: {
      date,
      selectedVisit,
      visitEditorOpen,
      scale,
      gutterInterval,
      legacyGrouping,
    },
    state: { employeeId },
  } = useAppSelector(selectors.selectPersonalPlan)
  const debug = useAppSelector(coreSelectors.selectDebug)
  const dispatch = useAppDispatch()
  const {
    state: { externalSystemId: me },
  } = useAuthentication()
  useEffect(() => {
    dispatch(actions.setEmployee(me))
  }, [dispatch, me])
  const handleCloseDialog = () => dispatch(actions.closeVisitEditor())

  return (
    <CalendarProvider
      calendarSelector={selectors.selectCalendar}
      calendarActions={actions}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        sx={{ background: '#1c78d3' }}
      >
        <ServiceVisitEditorDialog
          open={visitEditorOpen}
          visitId={selectedVisit}
          onClose={handleCloseDialog}
          debug={debug}
        />
        <Grid item xs="auto" md="auto"></Grid>
        <Grid item xs={8} md={10}>
          <DateController />
        </Grid>
        <Grid item xs>
          <Box pr={2} sx={{ textAlign: 'right' }}>
            <GlobalLoadingIndicator />
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <ZoomController />
          <EmployeeCalendar
            id={employeeId ?? ''}
            date={date}
            scale={scale}
            gutterInterval={gutterInterval}
            legacyGrouping={legacyGrouping}
          />
        </Grid>
      </Grid>
    </CalendarProvider>
  )
}
